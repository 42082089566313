import React from "react";
import "./Widgets1.css";
// import {
//   // TwitterTimelineEmbed,
//   // TwitterShareButton,
//   TwitterTweetEmbed,
// } from "react-twitter-embed";

function Widgets() {
  return (
    <div className="widgets">
      <div className="widgets__widgetContainer">
        {/* <h2>Updates</h2> */}

        {/* <TwitterTweetEmbed
          style="border: none"
          tweetId={"1400458250550611970"}
        /> */}

        <div className="quote">
          <blockquote>
            "What's something you thought would be hard until you tried it?"
            {/* <footer>
              &mdash;
              <cite>Toni Servillo</cite>
            </footer> */}
          </blockquote>
        </div>

        {/* <TwitterTimelineEmbed
          sourceType="profile"
          screenName="SchoolAhlcon"
          options={{ height: 400 }}
        /> */}

        <div className="widgets__widget">
          <h2>Guidelines</h2>
          <br></br>
          <ul className="no-list">
            <li>
              Users are refrained from posting anything other than educational
              content and mental health. All posts that violate this, will be deleted.
            </li>
            <br></br>
            <li>
              For adding images, copy the image address and paste it in the
              image url field.
            </li>
            <br></br>
            <li>
              If you wish to contribute to this website, please click on the
              Community Resources option in the sidebar for further
              instructions.
            </li>
          </ul>
        </div>
        <br></br>
        {/* <TwitterShareButton
          url={"https://stu-clan.web.app/"}
          options={{ text: "StuClan is so gooood!!", via: "achintyajha05" }}
        /> */}
      </div>
    </div>
  );
}

export default Widgets;
