import React, { useState } from "react";
import "./PostBox.css";
import { Avatar, Button } from "@material-ui/core";
import db from "./firebase";
import { AvatarGenerator } from "random-avatar-generator";

function PostBox() {
  const [tweetMessage, setTweetMessage] = useState("");
  const [tweetImage, setTweetImage] = useState("");
  const [tweetUsername, setTweetUsername] = useState("");

  const sendTweet = (e) => {
    e.preventDefault();

    const generator = new AvatarGenerator();

    db.collection("posts").add({
      displayName: tweetUsername,
      // username: 'achintyajha',
      text: tweetMessage,
      image: tweetImage,
      avatar:
        // "https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png",
        generator.generateRandomAvatar(),
      username: "Student",
      time: new Date().getTime(),
    });

    setTweetMessage("");
    setTweetImage("");
    setTweetUsername("");
  };

  return (
    <div className="PostBox">
      <form onSubmit={sendTweet}>
        <div className="PostBox__input">
          <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToRvlWA1DiDf8YfOzSTteQ1TAdcnKGHaUyLg&usqp=CAU" />
          <textarea
            onChange={(e) => setTweetMessage(e.target.value)}
            value={tweetMessage}
            placeholder="Share with the community....."
            type="text"
            required
          />
        </div>

        <input
          onChange={(e) => setTweetUsername(e.target.value)}
          value={tweetUsername}
          className="PostBox__userInput"
          placeholder="Enter your name...."
          type="text"
          required
        />

        <input
          onChange={(e) => setTweetImage(e.target.value)}
          value={tweetImage}
          className="PostBox__imageInput"
          placeholder="Optional: Enter image URL...."
          type="text"
        />

        <Button type="submit" className="PostBox__postButton">
          Post
        </Button>
      </form>
    </div>
  );
}

export default PostBox;
