import React, { useState } from "react";
import "./Sidebar1.css";

import SidebarOption from "./SidebarOption";
import HomeIcon from "@material-ui/icons/Home";
// import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import InfoIcon from "@material-ui/icons/Info";
// import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import { Button } from "@material-ui/core";

import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    border: 0,
    outline: "none",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Sidebar() {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <div className="sidebar">
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <div className="sidebar__modal">
            <h2>About this Website🖥</h2>
            <br></br>
            <p>
              StuClan is the platform for <strong>you</strong> to talk about
              mental health. The whole idea of this application is to provide a
              place where people can share free study resources that helped
              them, talk about their mental health, or even share advice for
              fellow users.
            </p>
            <br></br>
            <p>
              I am Achintya and I developed this website using React and
              Firebase. I am a high school senior at Ahlcon International
              School. Find me at -{" "}
              <a
                rel="noreferrer"
                className="links"
                target="_blank"
                href="https://www.achintyajha.com"
              >
                achintyajha.com
              </a>
            </p>
          </div>
          <Button onClick={handleClose} className="PostBox__postButton">
            Close
          </Button>
        </div>
      </Modal>

      <Modal open={open1} onClose={handleClose1}>
        <div style={modalStyle} className={classes.paper}>
          <div className="sidebar__modal">
            <h2>Study Resources 📚</h2>
            <br></br>
            <ul>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://khanacademy.org/"
                >
                  Khan Academy
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://oyc.yale.edu/"
                >
                  Yale Open Courses
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.coursera.org/"
                >
                  Coursera
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="http://www.openculture.com/freeonlinecourses"
                >
                  Open Culture Online Courses
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="https://www.edx.org/">
                  edX
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.freecodecamp.com/"
                >
                  freeCodeCamp
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.theodinproject.com/"
                >
                  Odin Project
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://betterexplained.com/"
                >
                  Better Explained
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="https://ocw.mit.edu/">
                  MIT Open Course Ware
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://ebookfoundation.github.io/free-programming-books/"
                >
                  Ebook Foundation
                </a>
              </li>
            </ul>
          </div>
          <Button onClick={handleClose1} className="PostBox__postButton">
            Close
          </Button>
        </div>
      </Modal>

      <Modal open={open2} onClose={handleClose2}>
        <div style={modalStyle} className={classes.paper}>
          <div className="sidebar__modal">
            <h2>Mental Health First!🧘‍♂️</h2>
            <p className="muted">
              You can add to this list by emailing at- <br></br>
              <a
                rel="noreferrer"
                className="links"
                href="mailto:contact.stuclan@gmail.com"
              >
                contact.stuclan@gmail.com
              </a>
            </p>
            <br></br>
            <ul>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://sentimate.org/model"
                >
                  See how you're feeling
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.youtube.com/watch?v=inpok4MKVLM"
                >
                  5 minute meditation
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.youtube.com/watch?v=O-6f5wQXSu8"
                >
                  10 minute meditation
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.mentalhealth.org.uk/publications/how-to-mental-health"
                >
                  A guide to better mental health
                </a>
              </li>
            </ul>
          </div>
          <Button onClick={handleClose2} className="PostBox__postButton">
            Close
          </Button>
        </div>
      </Modal>

      {/* Icon */}
      <div className="logo">
        <h2 className="sidebar__Icon">
          <span className="sidebar__Icon1">Stu</span>
          <span className="sidebar__Icon2">Clan</span>
        </h2>
        <a rel="noreferrer" target="_self" href="https://sentimate.org">
          By SentiMate
        </a>
      </div>
      <div>
        <SidebarOption active Icon={HomeIcon} text="Home" />
      </div>
      <div onClick={handleOpen1}>
        <SidebarOption
          Icon={CollectionsBookmarkIcon}
          text="Educational Resources"
        />
      </div>
      <div onClick={handleOpen2}>
        <SidebarOption Icon={LocalLibraryIcon} text="Wellness Resources" />
      </div>
      <div className="info" onClick={handleOpen}>
        <SidebarOption Icon={InfoIcon} text="About" />
      </div>
    </div>
  );
}

export default Sidebar;
