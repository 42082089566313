import firebase from "firebase/app";
import "firebase/firestore"; // for cloud firestore

const firebaseConfig = {
  apiKey: "AIzaSyDhphyjgcy2eSrKs8gn2B4PQygXcndGxEg",
  authDomain: "stu-clan.firebaseapp.com",
  projectId: "stu-clan",
  storageBucket: "stu-clan.appspot.com",
  messagingSenderId: "381147826652",
  appId: "1:381147826652:web:6ed4a241829f93a6c3a719",
  measurementId: "G-1P8W0F0VBS",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export default db;
