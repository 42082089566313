import React, { useState, useEffect } from "react";
import "./Feed1.css";
import PostBox from "./PostBox";
import Post from "./Post";
import db from "./firebase";
import FlipMove from "react-flip-move";
import { Avatar } from "@material-ui/core";

function Feed() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    db.collection("posts")
      .orderBy("time", "desc")
      .onSnapshot((snapshot) =>
        setPosts(snapshot.docs.map((doc) => doc.data()))
      );
  }, []);

  return (
    <div className="feed">
      {/* Header */}
      <div className="feed__header">
        <h2>
          Check out
          <a rel="noreferrer" target="_self" href="https://sentimate.org">
            SentiMate
          </a>
          today
        </h2>
      </div>

      {/* Tweet Box */}
      <PostBox />
      {/* Post */}

      <div className="pinned_post">
        <div className="post__avatar">
          <Avatar src="https://i.ibb.co/XLCWV7h/logo1.png" />
        </div>
        <div className="post__body">
          <div className="post__header">
            <div className="post__headerText">
              <h3>
                StuClan <span className="post__headerSpecial">• 📌 Pinned</span>
              </h3>
            </div>
            <div className="post__headerDescription">
              <p>
                Hey everyone, welcome to this website! Get started by posting
                anything ranging from how you are feeling to mental health
                advice or even educational resources in the "Share with the
                community" prompt!
                <br />
                Take a moment to share what you feel like and interact with
                others, your effort would be highly appreciated! Also don't
                forget to check out
                <a
                  rel="noreferrer"
                  target="_self"
                  href="https://sentimate.org/"
                >
                  SentiMate.
                </a>
                <br />
                <strong>
                  {" "}
                  To add images, search for an image / GIF on google and paste
                  its "image-adress" in the image url section.
                </strong>
              </p>

              <p className="shameless-self-promo">
                Incase you want to know more about the developer, visit-{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://achintyajha.com"
                >
                  Achintya's Website.
                </a>
              </p>
            </div>
          </div>
          {/* <img src="https://img.wattpad.com/f99caa3fd63a83e3376fd63a66ea1f40918f9012/68747470733a2f2f73332e616d617a6f6e6177732e636f6d2f776174747061642d6d656469612d736572766963652f53746f7279496d6167652f585038697136434747784d4965513d3d2d3631343836323636362e313534383737313536663334613431373137373436303731363632332e676966"></img> */}
        </div>
      </div>

      <FlipMove>
        {posts.map((post) => (
          <Post
            key={post.text}
            displayName={post.displayName}
            username={post.username}
            text={post.text}
            avatar={post.avatar}
            image={post.image}
            time={post.time}
          />
        ))}
      </FlipMove>
    </div>
  );
}

export default Feed;
