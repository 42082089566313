import React, { forwardRef } from "react";
import "./Post.css";
import { Avatar } from "@material-ui/core";
// import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
// import RepeatIcon from "@material-ui/icons/Repeat";
// import FavoriteIcon from "@material-ui/icons/Favorite";

const Post = forwardRef(
  ({ displayName, username, text, image, avatar, time }, ref) => {
    return (
      <div className="post" ref={ref}>
        <div className="post__avatar">
          <Avatar src={avatar} />
        </div>
        <div className="post__body">
          <div className="post__header">
            <div className="post__headerText">
              <h3>
                {displayName}{" "}
                <span className="post__headerSpecial">@{username}</span>
              </h3>
            </div>
            <div className="post__headerDescription">
              <p>{text}</p>
            </div>
          </div>

          {(() => {
            if (image) {
              return <img src={image}></img>;
            } else {
              return ;
            }
          })()}

          
        </div>
      </div>
    );
  }
);

export default Post;
